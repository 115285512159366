  export const theme_colors =   {
    primary_color: '#d990be',
    primary_lite_color: '#f5b3dd',
    secondary_color: "#1d1d1e",
    secondary_lite_color: "#333333",
    olive: "#b1ce67",
    cream: "#F9F0BA",
    red: "#ff0000",
    black: "#000000"
  };

  export const TrackingNumbers = {
    FN8098MNOP: {
      id: 'FN8098MNOP', 
      pass: '0'
    },
    FN8098MNON: {
      id: 'FN8098MNON', 
      pass: '1'
    },
    MN8098345N: {
      id: 'MN8098345N', 
      pass: '2'
    },
    DN8098345N: {
      id: 'DN8098345N', 
      pass: '3'
    },
    DN80983452: {
      id: 'DN80983452', 
      pass: '4'
    }
  };

  export const Packages = [
    {
      id: '0',
      tracking_id: 'FN8098MNOP',
      status: "IN TRANSIT",
      sender_name: "ETHAN DOMINIQUE",
      sender_address: "ULLMANNSTRASSE 18B ST. GALLEN, SG, CH, 9014",
      receiver_name: "DORIS GREG",
      receiver_address: "16 OWUFUJU STREET, OFF OKEHO STREE IRE.AKARI ESTATE RD, OSHODI-ISOLO LAGOS, NG",
      ship_date: "Tue 6/22/2021 04:31 PM",
      origin: "ST. GALLEN, SG, CH, 9014",
      current_location: "FELTHAM, MI",
      destination: "LAGOS, NG",
      standard_transit: "Wednesday, 30/06/2021 by 20:00",
      service_type: "FST Courier Same Day Delivery",
      number_of_pieces: "1",
      item_weight: "25.0 kg",
      total_shipment_weight: "54.70 LB"
    },
    {
      id: '1',
      tracking_id: 'FN8098MNON',
      status: "PICKED UP",
      sender_name: "ETHAN DOMINIQUE",
      sender_address: "ULLMANNSTRASSE 18B ST. GALLEN, SG, CH, 9014",
      receiver_name: "CHAO QWIN",
      receiver_address: "16 OWUFUJU STREET, OFF OKEHO STREE IRE.AKARI ESTATE RD, OSHODI-ISOLO LAGOS, NG",
      ship_date: "",
      origin: "",
      current_location: "",
      destination: "",
      standard_transit: "",
      service_type: "FST Courier Same Day Delivery",
      number_of_pieces: "1",
      item_weight: "25.0 kg",
      total_shipment_weight: ""
    },
    {
      id: '2',
      tracking_id: 'MN8098345N',
      status: "IN TRANSIT",
      sender_name: "NATHAN JOSEPH",
      sender_address: "750 Harrison St, San Francisco, CA, 94107",
      receiver_name: "MARCO XIE",
      receiver_address: "28317 Seamount Dr, Rancho Palos Verdes, CA, 90275",
      ship_date: "Mon 12/08/2024 02:58 PM",
      origin: "San Francisco, CA 94109",
      current_location: "SANTA NELLA, CA",
      destination: "Rancho Palos Verdes, CA",
      standard_transit: "Wednesday, 14/08/2024 by 20:00",
      service_type: "FST Courier 2nd Day Delivery",
      number_of_pieces: "1",
      item_weight: "1.45 lb",
      total_shipment_weight: "1.50 LB"
    },
    {
      id: '3',
      tracking_id: 'DN8098345N',
      status: "PICKED UP",
      sender_name: "ALEXIS XJ",
        sender_address: "346 HARRISON St #1, MARQUETTE, MI 49855",
      receiver_name: "JEFF ROEDIGER",
      receiver_address: "7720 RILEY ROAD CELINA, OH 45822",
      ship_date: "",
      origin: "",
      current_location: "",
      destination: "",
      standard_transit: "",
      service_type: "FST Courier 3rd Day Delivery",
      number_of_pieces: "9",
      item_weight: "82.3 kg",
      total_shipment_weight: ""
    },
    {
      id: '4',
      tracking_id: 'DN80983452',
      status: "IN TRANSIT",
      sender_name: "ALEXIS XJ",
      sender_address: "346 HARRISON St 1, MARQUETTE, MI 49855",
      receiver_name: "JEFF ROEDIGER",
      receiver_address: "7720 RILEY ROAD CELINA, OH 45822",
      ship_date: "Mon 18/12/2024 04:18 PM",
      origin: "MARQUETTE, MI 49857",
      current_location: "ST. LOUIS, MI",
      destination: "CELINA, OH",
      standard_transit: "Friday, 20/12/2024 by 20:00",
      service_type: "FST Courier 3rd Day Delivery",
      number_of_pieces: "9",
      item_weight: "200.45 lb",
      total_shipment_weight: "200.50 LB"
    },
  ];


  export const products = [
    {
      id: 1,
      name: "fashion kraft bag",
      description: "Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.",
      img: "image",
    },

  ];


  export const bagTypes = [
    { 
      id: "0", 
      name: "bag", 
      unit: 50,
      package_weight: 2,
      package_dimensions: {
        length: 12,
        width: 13,
        height: 14
      } 
    },
    { 
      id: "1", 
      name: "bag without handles", 
      unit: 50,
      package_weight: 2,
      package_dimensions: {
        length: 12,
        width: 13,
        height: 14
      } 
    },
    { 
      id: "2", 
      name: "food box", 
      unit: 50,
      package_weight: 2,
      package_dimensions: {
        length: 12,
        width: 13,
        height: 14
      } 
    },
    { 
      id: "3", 
      name: "food wrap", 
      unit: 100,
      package_weight: 2,
      package_dimensions: {
        length: 12,
        width: 13,
        height: 14
      } 
    },
    { 
      id: "4", 
      name: "cup", 
      unit: 50,
      package_weight: 2,
      package_dimensions: {
        length: 12,
        width: 13,
        height: 14
      } 
    },
    { 
      id: "5", 
      name: "cutlery", 
      unit: 100,
      package_weight: 2,
      package_dimensions: {
        length: 12,
        width: 13,
        height: 14
      } 
    },
    { 
      id: "6", 
      name: "cutlery cover", 
      unit: 100,
      package_weight: 2,
      package_dimensions: {
        length: 12,
        width: 13,
        height: 14
      } 
    },
    { 
      id: "7", 
      name: "rigid box", 
      unit: 2,
      package_weight: 2,
      package_dimensions: {
        length: 12,
        width: 13,
        height: 14
      } 
    },
    { 
      id: "8", 
      name: "corrugated box", 
      unit: 10,
      package_weight: 2,
      package_dimensions: {
        length: 12,
        width: 13,
        height: 14
      } 
    },
  ];

  export const local_states = [
    { id: 1, name: "Abia", delivery_rate:{ ABC: 276, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 48},
    { id: 2, name: "Adamawa", delivery_rate:{ ABC: 399, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
    { id: 3, name: "Akwa Ibom", delivery_rate:{ ABC: 289, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
    { id: 4, name: "Anambra", delivery_rate:{ ABC: 276, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
    { id: 5, name: "Bauchi", delivery_rate:{ ABC: 399, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
    { id: 6, name: "Bayelsa", delivery_rate:{ ABC: 289, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
    { id: 7, name: "Benue", delivery_rate:{ ABC: 289, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
    { id: 8, name: "Borno", delivery_rate:{ ABC: 399, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
    { id: 9, name: "Cross River", delivery_rate:{ ABC: 289, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
    { id: 10, name: "Delta", delivery_rate:{ ABC: 276, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
    { id: 11, name: "Ebonyi", delivery_rate:{ ABC: 276, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
    { id: 12, name: "Edo", delivery_rate:{ ABC: 276, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
    { id: 13, name: "Ekiti", delivery_rate:{ ABC: 276, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
    { id: 14, name: "Enugu", delivery_rate:{ ABC: 276, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
    { id: 15, name: "FCT - Abuja", delivery_rate:{ ABC: 276, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
    { id: 16, name: "Gombe", delivery_rate:{ ABC: 399, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
    { id: 17, name: "Imo", delivery_rate:{ ABC: 276, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
    { id: 18, name: "Jigawa", delivery_rate:{ ABC: 399, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
    { id: 19, name: "Kaduna", delivery_rate:{ ABC: 399, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
    { id: 20, name: "Kano", delivery_rate:{ ABC: 399, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
    { id: 21, name: "Katsina", delivery_rate:{ ABC: 399, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
    { id: 22, name: "Kebbi", delivery_rate:{ ABC: 399, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
    { id: 23, name: "Kogi", delivery_rate:{ ABC: 289, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
    { id: 24, name: "Kwara", delivery_rate:{ ABC: 276, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
    { id: 25, name: "Lagos"},
    { id: 26, name: "Nasarawa", delivery_rate:{ ABC: 399, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
    { id: 27, name: "Niger", delivery_rate:{ ABC: 399, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
    { id: 28, name: "Ogun", delivery_rate:{ ABC: 276, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
    { id: 29, name: "Ondo", delivery_rate:{ ABC: 276, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
    { id: 30, name: "Osun", delivery_rate:{ ABC: 276, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
    { id: 31, name: "Oyo", delivery_rate:{ ABC: 276, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
    { id: 32, name: "Plateau", delivery_rate:{ ABC: 289, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
    { id: 33, name: "Rivers", delivery_rate:{ ABC: 289, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
    { id: 34, name: "Sokoto", delivery_rate:{ ABC: 399, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
    { id: 35, name: "Taraba", delivery_rate:{ ABC: 399, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
    { id: 36, name: "Yobe", delivery_rate:{ ABC: 399, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
    { id: 37, name: "Zamfara", delivery_rate:{ ABC: 399, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
  ];

  export const local_areas = [
    { id: 1, state_id: 25, name: "Agege", delivery_rate:{ ACE: 200, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
    { id: 2, state_id: 25, name: "Alimosho", delivery_rate:{ ACE: 200, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
    { id: 3, state_id: 25, name: "Apapa", delivery_rate:{ ACE: 90, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
    { id: 4, state_id: 25, name: "Ifako-Ijaye", delivery_rate:{ ACE: 200, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
    { id: 5, state_id: 25, name: "Ikeja", delivery_rate:{ ACE: 90, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
    { id: 6, state_id: 25, name: "Kosofe", delivery_rate:{ ACE: 90, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
    { id: 7, state_id: 25, name: "Mushin", delivery_rate:{ ACE: 90, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
    { id: 8, state_id: 25, name: "Oshodi-Isolo", delivery_rate:{ ACE: 90, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
    { id: 9, state_id: 25, name: "Shomolu", delivery_rate:{ ACE: 90, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
    { id: 10, state_id: 25, name: "Eti-Osa", delivery_rate:{ ACE: 90, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
    { id: 11, state_id: 25, name: "Lagos Island", delivery_rate:{ ACE: 90, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
    { id: 12, state_id: 25, name: "Lagos Mainland", delivery_rate:{ ACE: 90, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
    { id: 13, state_id: 25, name: "Surulere", delivery_rate:{ ACE: 90, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
    { id: 14, state_id: 25, name: "Ojo", delivery_rate:{ ACE: 200, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
    { id: 15, state_id: 25, name: "Ajeromi-Ifelodun", delivery_rate:{ ACE: 200, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
    { id: 16, state_id: 25, name: "Amuwo-Odofin", delivery_rate:{ ACE: 200, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
    { id: 17, state_id: 25, name: "Badagry", delivery_rate:{ ACE: 200, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
    { id: 18, state_id: 25, name: "Ikorodu", delivery_rate:{ ACE: 200, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
    { id: 19, state_id: 25, name: "Ibeju-Lekki", delivery_rate:{ ACE: 200, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24},
    { id: 20, state_id: 25, name: "Epe", delivery_rate:{ ACE: 200, bike_rate: 2500, car_rate: 7000}, delivery_time_est: 24}
  ];